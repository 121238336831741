import React, { useEffect, useState } from 'react';

import { staticClient } from 'soapbox/api';

// Define the type for the colors object structure
interface Colors {
  primary: Record<string, string>;
  accent: Record<string, string>;
  success: Record<string, string>;
  danger: Record<string, string>;
  neutral: Record<string, string>;
}

const ColorSwatch: React.FC = () => {
  const [colors, setColors] = useState<Colors | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Fetch the JSON file on component mount
  useEffect(() => {
    const fetchColors = async () => {
      try {
        const { data } = await staticClient.get('/instance/soapbox.json');
        setColors(data.colors);
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    };

    fetchColors();
  }, []);

  if (process.env.NODE_ENV !== 'development' || !process.env.ENABLE_COLOR_SWATCH) {
    return null;
  }

  if (!colors) {
    return <div>Loading...</div>; // Render a loading state until the JSON is fetched
  }

  // Convert the colors object into a list of classes and hex values
  const colorList = Object.entries(colors).flatMap(([category, shades]) =>
    Object.entries(shades).map(([shade, hex]) => ({
      className: `bg-${category}-${shade}`,
      hex,
    })),
  );

  return (
    <>
      {!isVisible && (
        <div className='fixed bottom-4 right-4 z-[999]'>
          <button
            onClick={() => setIsVisible(true)}
            className='rounded bg-primary-200 p-2 shadow'
          >
            Color Swatch
          </button>
        </div>
      )}

      {isVisible && (
        <div className='fixed bottom-0 right-0 z-[999] border border-primary-300 bg-white p-4 text-black shadow-lg'>
          <button
            onClick={() => setIsVisible(false)}
            className='absolute right-2 top-2 text-primary-600 hover:text-primary-800'
          >
            Close
          </button>
          <div className='mt-8 grid grid-cols-4 gap-4'>
            {colorList.map(({ className, hex }, index) => (
              <div key={index} className='flex items-center space-x-2'>
                <div className={`h-6 w-6 ${className}`} />
                <div>
                  <p className='text-xs font-medium'>{className}</p>
                  <p className='text-xs text-black/60'>{hex as string}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ColorSwatch;
